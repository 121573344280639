import Image, { ImageProps } from 'next/image'
import { useEffect, useRef, useState } from 'react'
import { useMediaQuery } from 'react-responsive'
import 'swiper/css'
import 'swiper/css/pagination'
import { Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'

import styles from './vehicle-image.module.scss'
import { Button, ButtonHierarchy } from '@/components/styleguide/Button'
import { constants } from '@/lib/constants'
import { Size, Theme } from '@/types/system'
import { VehicleGalleryItem } from '@/types/vehicle'

export type VehicleImageWithGalleryProps = {
  alt: string
  height: number
  width: number
  images: VehicleGalleryItem[]
  quality?: number
  layout?: 'fill' | 'fixed' | 'intrinsic' | 'responsive' | undefined
  placeholder?: ImageProps['placeholder']
  blurDataURL?: string
  className?: string
  type: 'DEFAULT' | 'GALLERY' | 'BROWSE' | 'HOMEPAGE'
  unoptimized?: boolean
}

export const VehicleImageWithGallery = ({
  alt,
  height,
  width,
  images,
  quality = 75,
  layout = undefined,
  placeholder = undefined,
  blurDataURL,
  className = '',
  unoptimized = false,
  type = 'DEFAULT',
}: VehicleImageWithGalleryProps) => {
  const swiperRef = useRef()
  const [isAtStart, setIsAtStart] = useState(true)
  const [isAtEnd, setIsAtEnd] = useState(false)
  const isMobile = useMediaQuery({ maxWidth: 991 })

  useEffect(() => {
    swiperRef.current.allowTouchMove = isMobile
  }, [isMobile])

  return (
    <Swiper
      onSwiper={swiper => {
        swiperRef.current = swiper
      }}
      draggable={isMobile}
      allowTouchMove={isMobile}
      className={styles.gallery}
      pagination={{
        type: 'bullets',
        bulletElement: 'div',
        dynamicBullets: true,
        dynamicMainBullets: 3,
      }}
      centeredSlides={true}
      onSlideChange={swiper => {
        setIsAtStart(swiper.progress === 0)
        setIsAtEnd(swiper.progress === 1)
      }}
      modules={[Pagination]}>
      {images
        .filter(image => image.angleType === 'EXTERIOR')
        .sort((a, _) => (a.angle === 'lowaggressive' ? -1 : 1))
        .map((image, index) => (
          <SwiperSlide
            className={`${styles['gallery--item']} ${
              type !== 'GALLERY' && type !== 'BROWSE' && type !== 'HOMEPAGE' && image.path
                ? styles['reverse-image']
                : ''
            }`}
            key={image.path}>
            <div>
              <Image
                src={`${constants.imageHost.vehicleImage}/${image.path}`}
                alt={alt}
                height={height}
                width={width}
                priority={index === 0}
                quality={quality}
                layout={layout}
                placeholder={placeholder}
                blurDataURL={blurDataURL}
                className={className}
                unoptimized={unoptimized}
              />
            </div>
          </SwiperSlide>
        ))}
      <div
        className={styles.navigation}
        data-navigation-position={isAtStart ? 'start' : isAtEnd ? 'end' : 'middle'}>
        <Button
          onClick={() => {
            swiperRef?.current.slidePrev()
          }}
          icon="ChevronLeft"
          size={Size.ExtraSmall}
          hierarchy={ButtonHierarchy.Filled}
          theme={Theme.dark}
        />
        <Button
          onClick={() => {
            swiperRef?.current.slideNext()
          }}
          theme={Theme.dark}
          icon="ChevronRight"
          size={Size.ExtraSmall}
          hierarchy={ButtonHierarchy.Filled}
        />
      </div>
    </Swiper>
  )
}
